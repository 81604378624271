module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":false,"showCaptions":true,"wrapperStyle":"width: 100vw;\n              position: relative;\n              left: 50%;\n              right: 50%;\n              margin-left: -50vw;\n              margin-right: -50vw;","quality":100},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"72","icon":"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"var(--linkHeader)\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\"><path d=\"M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71\"></path><path d=\"M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71\"></path></svg>","className":"autolink-headers","maintainCase":false,"removeAccents":true,"isIconAfterHeader":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/(projects|blog)/([^/]+/)?"}],"excludePaths":["/","/about","/experience","/projects/","/blog/"],"height":2,"prependToBody":false,"color":"var(--pageProgress)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ajmal Afif","short_name":"Ajmal Afif","start_url":"/","background_color":"#0055ff","theme_color":"#0055ff","display":"minimal-ui","icon":"src/img/favicon@3x.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ccf6d395d8e13f358d32a65318df4c82"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-15183705-12","cookieName":"gatsby-gdpr-google-analytics"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","htmlTitle":"Ajmal Afif‘s"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
